import React, { useState } from 'react';
import PropTypes from 'prop-types';

function LoginPage({ onLoginSuccess }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you would typically handle the login logic, possibly checking
        // against an authentication service and then calling onLoginSuccess
        // if the credentials are valid.
        if (username.toLowerCase() === 'poc' && password.toLowerCase() === 'poc') {
            onLoginSuccess();
        }
        else {
            setMessage('Invalid username or password');
        }
    };

    return (
        <div className="container" style={{ maxWidth: '300px' }}>
            {(message !== null && message !== undefined && message !== '')
                ? <div style={{ color: 'red' }}>{message}</div>
                : ''}

            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="username">Email address:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        style={{ width: '100%', padding: '8px' }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <button type="submit" style={{ width: '100%' }}>Login</button>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <a href="#" onClick={() => {/* handle forgot password logic */ }}>
                        Forgot password?
                    </a>
                </div>
            </form>
        </div>
    );
}

LoginPage.propTypes = {
    onLoginSuccess: PropTypes.func.isRequired,
};

export default LoginPage;
