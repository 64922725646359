import React from 'react';
import PropTypes from 'prop-types';

function TextInput({ job, setJob, field, label }) {
    const handleChange = (event) => {
        setJob({ ...job, [field]: event.target.value });
    };

    return (
        <input
            value={job[field]}
            type="text"
            onChange={handleChange}
            className="input-text"
            placeholder={label || ''}
        />
    );
}

TextInput.propTypes = {
    job: PropTypes.object.isRequired,
    setJob: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default TextInput;