import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function CameraCapture({ job, setJob, fieldName, onExit }) {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [stream, setStream] = useState(null);
    let videoStream = null;

    useEffect(() => {
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(stream => {
                    videoStream = stream;
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                    }
                })
                .catch(err => {
                    console.error('Error accessing the camera', err);
                });
        }
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
        };
    }, [stream]);

    const stopCameraFeed = () => {
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
            setStream(null);
        }
    };

    const takePicture = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        if (video && canvas) {
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(blob => {
                const updatedJob = { ...job, [fieldName]: URL.createObjectURL(blob) };
                setJob(updatedJob);
            });
        }
        stopCameraFeed();
        onExit();
    };

    return (
        <div className="camera-capture">
            <div style={{ position: 'absolute', top: '5px', left: '0px' }} >
                <button onClick={takePicture}>Take Picture</button>
                &nbsp;
                <button onClick={onExit}>Exit</button>
            </div>
            <video ref={videoRef} autoPlay playsInline style={{ position: 'absolute', top: '50px' }} width="100%"></video>
            <canvas ref={canvasRef} style={{ position: 'absolute', display: 'none', top: '50px', width: '100 % ' }}></canvas>
        </div>
    );
}

CameraCapture.propTypes = {
    onExit: PropTypes.func.isRequired,
    job: PropTypes.func.isRequired,
    setJob: PropTypes.func.isRequired,
    fieldName: PropTypes.func.isRequired,
};

export default CameraCapture;