import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SectionTitle from './SectionTitle.jsx';
import Quality from './Quality.jsx';
import Comments from './Comments';
import PhotoList from './PhotoList';
import TextInput from './TextInput.jsx';
import DateInput from './DateInput.jsx';
import Camera from './Camera';

function EditJobPage({ job: jobData, onSave, onCancel }) {
    //<Camera onExit={handleExitCamera} />

    const [job, setJob] = useState(jobData);
    //const [isCameraActive, setCameraActive] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [photoField, setPhotoField] = useState('');

    const handleDeletePhoto = (category, index) => {
        const updatedPhotos = job[`${category}Photos`].filter((_, i) => i !== index);
        setJob({ ...job, [`${category}Photos`]: updatedPhotos });
    };

    const handleUploadPhoto = (category, event) => {
        const file = event.target.files[0];
        console.log('Uploading photo to', category, file);
    };

    const handleTakePhoto = (field) => {
        setShowCamera(true);
        setPhotoField(field);
    };

    // const handleExitCamera = () => {
    //     //setCameraActive(false);
    // };

    const onDebug = () => {
        //alert(JSON.stringify(job, null, 4));
    };

    return (
        <div className="container">
            {showCamera && (
                <Camera
                    job={job}
                    setJob={setJob}
                    fieldName={photoField}
                    onExit={() => setShowCamera(false)}
                />
            )}

            <div>
                <button type="button" onClick={() => onSave(job)}>Save</button>
                &nbsp;
                <button type="button" onClick={onCancel}>Cancel</button>
                &nbsp;
                <button type="button" onClick={onDebug}>Debug</button>
            </div>

            <form onSubmit={(e) => e.preventDefault()}>
                <div>
                    <SectionTitle text="Job details" />

                    <label htmlFor="address">Address</label>
                    <Comments
                        id="address"
                        job={job}
                        setJob={setJob}
                        field="address"
                    />

                    <label htmlFor="client">Client</label>
                    <TextInput
                        id="client"
                        job={job}
                        setJob={setJob}
                        field="client"
                    />

                    <label htmlFor="data">Date</label>
                    <DateInput
                        id="date"
                        job={job}
                        setJob={setJob}
                        field="date"
                    />


                    <label htmlFor="data">Weather</label>
                    <Comments
                        id="weather"
                        job={job}
                        setJob={setJob}
                        field="weather"
                    />
                </div>

                <div>
                    <SectionTitle text="Driveway" />
                    <Quality job={job} setJob={setJob} qualityProperty="drivewayQuality" />
                    <PhotoList
                        photos={job.kitchenPhotos?.map((photo) => ({ ...photo, category: 'kitchen' }))}
                        onDeletePhoto={(index) => handleDeletePhoto('kitchen', index)}
                        onTakePhoto={() => handleTakePhoto('kitchen')}
                        onUploadPhoto={(event) => handleUploadPhoto('kitchen', event)}
                    />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="drivewayComments"
                    />

                    <SectionTitle text="Fencing" />
                    <Quality job={job} setJob={setJob} qualityProperty="fencingQuality" />
                    <PhotoList
                        photos={job.fencingPhotos?.map((photo) => ({ ...photo, category: 'fencing' }))}
                        onDeletePhoto={(index) => handleDeletePhoto('fencing', index)}
                        onTakePhoto={() => handleTakePhoto('fencing')}
                        onUploadPhoto={(event) => handleUploadPhoto('fencing', event)}
                    />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="fencingComments"
                    />

                    <SectionTitle text="Retaining" />
                    <Quality job={job} setJob={setJob} qualityProperty="retainingQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="retainingComments"
                    />

                    <SectionTitle text="Pathways" />
                    <Quality job={job} setJob={setJob} qualityProperty="pathwayQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="pathwayComments"
                    />

                    <SectionTitle text="Decking" />
                    <Quality job={job} setJob={setJob} qualityProperty="deckingQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="deckingComments"
                    />

                    <SectionTitle text="Roof & Gutters" />
                    <Quality job={job} setJob={setJob} qualityProperty="roofGutterQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="roofGutterComments"
                    />

                    <SectionTitle text="Lawns" />
                    <Quality job={job} setJob={setJob} qualityProperty="lawnQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="lawnComments"
                    />

                    <SectionTitle text="Kitchen" />
                    <Quality job={job} setJob={setJob} qualityProperty="kitchenQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="kitchenComments"
                    />

                    <SectionTitle text="Garage" />
                    <Quality job={job} setJob={setJob} qualityProperty="garageQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="garageComments"
                    />

                    <SectionTitle text="Cladding" />
                    <Quality job={job} setJob={setJob} qualityProperty="claddingQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="claddingComments"
                    />

                    <SectionTitle text="Sub- Floor" />
                    <Quality job={job} setJob={setJob} qualityProperty="subfloorQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="subfloorComments"
                    />

                    <SectionTitle text="Lounge" />
                    <Quality job={job} setJob={setJob} qualityProperty="loungeQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="loungeComments"
                    />

                    <SectionTitle text="Laundry" />
                    <Quality job={job} setJob={setJob} qualityProperty="laundryQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="laundryComments"
                    />

                    <SectionTitle text="Bathrooms" />
                    <Quality job={job} setJob={setJob} qualityProperty="bathroomsQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="bathroomsComments"
                    />

                    <SectionTitle text="Roof space" />
                    <Quality job={job} setJob={setJob} qualityProperty="roofspaceQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="roofspaceComments"
                    />

                    <SectionTitle text="Power/fuse board" />
                    <Quality job={job} setJob={setJob} qualityProperty="powerFuseBoardQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="powerFuseBoardComments"
                    />

                    <SectionTitle text="Heating" />
                    <Quality job={job} setJob={setJob} qualityProperty="heatingQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="heatingComments"
                    />

                    <SectionTitle text="Plumbing / Hot water cylinder" />
                    <Quality job={job} setJob={setJob} qualityProperty="plumbingHwcQuality" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="plumbingHwcComments"
                    />

                    <SectionTitle text="Additional notes" />
                    <Comments
                        job={job}
                        setJob={setJob}
                        field="additionalComments"
                    />
                </div>

            </form>
        </div>
    );
}

EditJobPage.propTypes = {
    job: PropTypes.shape({
        id: PropTypes.string,
        address: PropTypes.string,
    }),
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default EditJobPage;