import React from 'react';
import PropTypes from 'prop-types';

function SectionTitle({ text }) {
    return <h2 className="section-title">{text}</h2>;
}

SectionTitle.propTypes = {
    text: PropTypes.string.isRequired
};

export default SectionTitle;