import React from 'react';
import PropTypes from 'prop-types';
import Photo from './Photo';

function PhotoList({ photos, onDeletePhoto, onTakePhoto, onUploadPhoto }) {
    return (
        <div className="photo-list-container">
            {photos?.length > 0 ? (
                <div className="photo-list">
                    {photos?.map((photo, index) => (
                        <Photo
                            key={index}
                            src={photo.src}
                            alt={photo.alt}
                            onDelete={() => onDeletePhoto(photo.category, index)}
                        />
                    ))}
                </div>
            ) : (
                <div className="no-photo">
                    No photos
                </div>
            )}
            <div className="photo-controls">
                <button onClick={onTakePhoto}>Take Photo</button>
                <input
                    type="file"
                    accept="image/*"
                    onChange={onUploadPhoto}
                    style={{ display: 'none' }}
                    id="file-upload"
                />
                <label htmlFor="file-upload" className="upload-button">
                    Pick
                </label>
            </div>

        </div>
    );
}

PhotoList.propTypes = {
    photos: PropTypes.arrayOf(PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
        category: PropTypes.string.isRequired,
    })).isRequired,
    onDeletePhoto: PropTypes.func.isRequired,
    onTakePhoto: PropTypes.func.isRequired,
    onUploadPhoto: PropTypes.func.isRequired,
};

export default PhotoList;