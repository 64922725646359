//const API_BASE_URL = 'https://richochet.link';

export default async () => {
    return [
        {
            id: '2124234234',
            address: '22 Chestnut Street, Silverton, Upper Hutt'
        },
        {
            id: '2124234233',
            address: '33 Jackson Drive, Edgemead, Lower Hutt'
        }
    ];
    /*
        try {
            // const response = await fetch(`${API_BASE_URL}/jobs`);
            // if (!response.ok) {
            //     throw new Error(`HTTP error! status: ${response.status}`);
            // }
            // const result = await response.json();
            // console.log(result);
            return [
                {
                    id: '2124234234',
                    address: '22 Chestnut Street, Silverton, Upper Hutt'
                },
                {
                    id: '2124234233',
                    address: '33 Jackson Drive, Edgemead, Lower Hutt'
                }
            ];
        }
        catch (error) {
            console.error('Fetching jobs failed:', error);
            throw error;
        }
        */
};
