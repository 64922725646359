import React from 'react';
import PropTypes from 'prop-types';

function Quality({ job, setJob, qualityProperty }) {
    const qualities = [
        {
            key: 'good',
            name: 'Good'
        },
        {
            key: 'ok',
            name: 'Ok'
        },
        {
            key: 'bad',
            name: 'Bad'
        },
        {
            key: 'na',
            name: 'n/a'
        }
    ];

    const handleSelectQuality = (quality) => {
        setJob({ ...job, [qualityProperty]: quality });
    };

    return (
        <div className="quality-container">
            {qualities.map((quality) => (
                <button
                    key={quality.key}
                    className={`quality-button ${(job && job[qualityProperty] === quality.key) ? 'selected' : ''}`}
                    onClick={() => handleSelectQuality(quality.key)}
                >
                    {quality.name}
                </button>
            ))}
        </div>
    );
}

Quality.propTypes = {
    job: PropTypes.object.isRequired,
    setJob: PropTypes.func.isRequired,
    qualityProperty: PropTypes.string.isRequired,
};


export default Quality;