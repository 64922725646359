import React from 'react';
import PropTypes from 'prop-types';

function DateInput({ job, setJob, field, label }) {
    const handleChange = (event) => {
        setJob({ ...job, [field]: event.target.value });
    };

    return (
        <div className="comments-container">
            <input
                value={job[field]}
                type="date"
                onChange={handleChange}
                className="input-date"
                placeholder={label || ''}
            />
        </div>
    );
}

DateInput.propTypes = {
    job: PropTypes.object.isRequired,
    setJob: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default DateInput;