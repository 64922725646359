import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import listJobsService from '../services/listJobs.service';

function ListPage({ onEditJob }) {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        listJobsService()
            .then(data => {
                setJobs(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching jobs:', error);
                setLoading(false);
            });
    }, []);

    const handleCreateJob = () => {
        const job = {};
        onEditJob(job);
    };

    return (
        <div className="container">
            <h1>Your recent jobs...</h1>

            <button className="create-job-button" onClick={handleCreateJob}>
                New job...
            </button>

            {loading && <p>Loading...</p>}

            <ul>
                {jobs.map((job) => (
                    <li key={job.id} onClick={() => onEditJob(job)}>{job.address}</li>
                ))}
            </ul>

            <button>Settings</button>
        </div>
    );
}

ListPage.propTypes = {
    onEditJob: PropTypes.func.isRequired,
};

export default ListPage;