import React from 'react';
import PropTypes from 'prop-types';

function Photo({ src, alt, onDelete }) {
    return (
        <div className="photo">
            <img src={src} alt={alt || 'Photo'} className="photo-image" />
            <button className="delete-button" onClick={onDelete}>Delete</button>
        </div>
    );
}

Photo.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
};

export default Photo;
