import React, { useState } from 'react';
import LoginPage from './components/LoginPage';
import ListJobs from './components/ListJobs';
import EditJobPage from './components/EditJobPage';
//import logo from './logo.svg';
import './styles/common.css';
import './App.css';
import CameraCapture from './components/Camera';

function App() {
    const [currentPage, setCurrentPage] = useState('login');
    const [selectedJob, setSelectedJob] = useState(null);

    const navigate = (page) => {
        setCurrentPage(page);
    };

    const handleEditJob = (job) => {
        setSelectedJob(job);
        setCurrentPage('edit');
    };

    const cancelEdit = () => {
        setCurrentPage('list');
    };

    const saveJob = (jobData) => {
        // logic to save the edited job
        const test = jobData !== null;
        console.log(test);
        setCurrentPage('list'); // Go back to list after save
    };

    const renderPage = () => {
        switch (currentPage) {
            case 'login':
                return <LoginPage onLoginSuccess={() => navigate('list')} />;

            case 'list':
                return <ListJobs onEditJob={handleEditJob} />;

            case 'edit':
                return <EditJobPage job={selectedJob} onSave={saveJob} onCancel={cancelEdit} />;

            case 'take-photo':
                return <CameraCapture />;

            default:
                return <LoginPage onLoginSuccess={() => navigate('list')} />;
        }
    };

    return (
        <div>
            {renderPage()}
        </div>
    );
}

export default App;
