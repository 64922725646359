import React from 'react';
import PropTypes from 'prop-types';

function Comments({ job, setJob, field, label }) {
    const handleChange = (event) => {
        setJob({ ...job, [field]: event.target.value });
    };

    return (
        <div className="comments-container">
            <textarea
                value={job[field]}
                onChange={handleChange}
                rows="4"
                className="comments-textarea"
                placeholder={label || 'Comments'}
            ></textarea>
        </div>
    );
}

Comments.propTypes = {
    job: PropTypes.object.isRequired,
    setJob: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default Comments;